import {
  Skeleton,
  Box
} from '@mui/material';
import PageHeading from './PageHeading';
import Page from './Page';

const BuilderLoadingSkeleton = () => {
  return (
    <Page>
      <PageHeading>
        Report Maker
      </PageHeading>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '16px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `"side aspect aspect aspect"`,
        marginBottom: '32px'
      }}>
        <Skeleton variant='rectangular' sx={{ gridArea: 'aspect', height: { lg: '220px', md: '240px', sm: '450px', xs: '450px' } }} />
        <Skeleton variant='rectangular' sx={{ gridArea: 'side', height: { lg: '220px', md: '240px', sm: '450px', xs: '450px' } }} />
      </Box>
    </Page>
  )
}

export default BuilderLoadingSkeleton;
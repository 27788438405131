import {
    Box,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';

const Auth0LoadingWrapper = (props: { children?: ReactNode }) => {
    const { children } = props;
    const {
        isLoading,
        error
    } = useAuth0();

    if (isLoading)
        return (
            <Backdrop open={isLoading}>
                <CircularProgress sx={{ color: 'secondary.light'}}/>
            </Backdrop>
        )
    else if (error)
        return (
            <Box>Something went wrong...</Box>
        )
    else 
        return (
            <>
                {children}
            </>
        )
}

export default Auth0LoadingWrapper;
import { Template, TemplateSection, ReportSection } from './customTypes';

const report =  {
  "createReport": {
      "id": 26,
      "sections": [
        {
          name: 'intro',
          sortable: false,
          sentences: [
            {
                "tags": [
                    {
                        "name": "intro",
                        "id": 68,
                        "__typename": "Tag"
                    }
                ],
                "rating": 3,
                "text": "I'm happy to report that Alex has continued to make respectable progress in her studies.",
                "id": 274,
                "position": 0,
                "__typename": "Sentence"
            }
          ]
        },
        {
          name: 'positive_intro',
          sortable: false,
          sentences: [
            {
                "tags": [
                    {
                        "name": "positive",
                        "id": 28,
                        "__typename": "Tag"
                    },
                    {
                        "name": "template",
                        "id": 69,
                        "__typename": "Tag"
                    }
                ],
                "rating": 3,
                "text": "First, there are a few things which deserve recognition.",
                "id": 273,
                "position": 0,
                "__typename": "Sentence"
            }
          ]
        },
        {
          name: 'positive_body',
          sortable: true,
          sentences: [
            {
                "tags": [
                    {
                        "name": "writing",
                        "id": 11,
                        "__typename": "Tag"
                    }
                ],
                "rating": 3,
                "text": "She has demonstrated a good grasp of the basics of writing, as well as a fair degree of focus in completing the assigned tasks.",
                "id": 272,
                "position": 0,
                "__typename": "Sentence"
            },
            {
                "tags": [
                    {
                        "name": "focus",
                        "id": 2,
                        "__typename": "Tag"
                    }
                ],
                "rating": 4,
                "text": "Alex's attitude towards school and learning is exemplary.",
                "id": 271,
                "position": 1,
                "__typename": "Sentence"
            },
            {
                "tags": [
                    {
                        "name": "phonics",
                        "id": 7,
                        "__typename": "Tag"
                    }
                ],
                "rating": 4,
                "text": "She has displayed a good understanding of phonics concepts we have covered in class.",
                "id": 270,
                "position": 2,
                "__typename": "Sentence"
            }
          ]
        },
        {
          name: 'negative_intro',
          sortable: false,
          sentences: [
            {
                "tags": [
                    {
                        "name": "negative",
                        "id": 54,
                        "__typename": "Tag"
                    },
                    {
                        "name": "multiple",
                        "id": 70,
                        "__typename": "Tag"
                    }
                ],
                "rating": 3,
                "text": "While I am pleased with the above, several areas remain which require further attention.",
                "id": 269,
                "position": 0,
                "__typename": "Sentence"
            }
          ]
        },
        {
          name: 'negative_body',
          sortable: true,
          sentences: [
            {
                "tags": [
                    {
                        "name": "grammar",
                        "id": 18,
                        "__typename": "Tag"
                    }
                ],
                "rating": 2,
                "text": "She needs to continue practicing grammar in order to increase her focus on this skill.",
                "id": 268,
                "position": 0,
                "__typename": "Sentence"
            },
            {
                "tags": [
                    {
                        "name": "participation",
                        "id": 10,
                        "__typename": "Tag"
                    }
                ],
                "rating": 2,
                "text": "She has been very active in class, but she could be doing more to contribute.",
                "id": 267,
                "position": 1,
                "__typename": "Sentence"
            }
          ]
        },
        {
          name: 'outro',
          sortable: false,
          sentences: [
            {
                "tags": [
                    {
                        "name": "outro",
                        "id": 67,
                        "__typename": "Tag"
                    }
                ],
                "rating": 3,
                "text": "It has been a pleasure to work with her and her growth is commendable.",
                "id": 266,
                "position": 0,
                "__typename": "Sentence"
            }
          ]
        }
      ],
      "__typename": "Report"
  }
}

const template: Template = {
    name: 'default',
  sections: [
      {
          name: 'intro',
          sortable: false
      },
      {
          name: 'positive_intro',
          sortable: false
      },
      {
          name: 'positive_body',
          sortable: false
      },
      {
          name: 'negative_intro',
          sortable: false
      },
      {
          name: 'negative_body',
          sortable: false
      },
      {
          name: 'outro',
          sortable: false
      },
  ]
}

export { report, template }
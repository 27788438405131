import { useReactiveVar } from '@apollo/client';
import {
  Alert, AlertColor, Snackbar
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { appAlertMessagesVar } from '../cache';
import { Message } from '../customTypes';

const AppMessages = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageIndex, setMessageIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const appAlertMessages = useReactiveVar(appAlertMessagesVar);

  useEffect(() => {
    const queue = appAlertMessages.slice(messageIndex);
    setMessageIndex(messageIndex + queue.length);
    setMessages([...messages, ...queue]);
    console.log(queue);
    if(queue.length > 0 && messages.length === 0)
      setOpen(true);
  }, [appAlertMessages]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleExited = () => {
    setMessages(prevState => {
      const newState = [...prevState];
      if(newState.length > 1) {
        setOpen(true);
        return newState.slice(1);
      }
      else {
        return [];
      }
    });
  }

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={5000} 
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        severity={messages[0] ? messages[0].severity as AlertColor : 'info'}
        onClose={handleClose}
      >
        {messages[0] ? messages[0].text : ''}
      </Alert>
    </Snackbar>
  )
}

export default AppMessages;
import {
    ApolloProvider,
    ApolloClient,
    HttpLink,
    NormalizedCacheObject,
} from '@apollo/client';
import { cache } from '../cache';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useRef } from 'react';
    
const ApolloProviderWithAuth0 = (props: { children?: ReactNode }) => {
    const { children } = props;
    const { getAccessTokenSilently } = useAuth0();

    const httpLink = new HttpLink({
        uri: process.env.NODE_ENV == 'production' ? 
            process.env.REACT_APP_APOLLO_URI :
            process.env.REACT_APP_APOLLO_URI_DEV
    });

    const authLink = setContext(async (_, { headers, ...rest }) => {
        let token;
        try {
            // REMINDER fix for auth
            // token = await getAccessTokenSilently();
            token = undefined;
        } catch (error) {
            console.log(error);
        }

        if (!token) return { headers, ...rest };
        
        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `${token}`,
            },
        }
    });

    const client = useRef<ApolloClient<NormalizedCacheObject>>();

    if (!client.current) {
        client.current = new ApolloClient({
            link: authLink.concat(httpLink),
            cache,
        });
    }

    return (
        <ApolloProvider client={client.current}>
            {children}
        </ApolloProvider>
    );
}

export default ApolloProviderWithAuth0;
import {
  Box,
  CircularProgress
} from '@mui/material';
import Page from './Page';

const GenericLoadingSkeleton = () => {
  return (
    <Page>
      <Box sx={{
        width: '100%',
        height: '100%',
        minHeight: '300px',
        position: 'relative'
      }}>
        <CircularProgress sx={{ margin: 'auto' }} color='secondary'/>
      </Box>
    </Page> 
  )
}

export default GenericLoadingSkeleton
import {
  Box,
  Paper
} from '@mui/material';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Tooltip
} from 'recharts';
import { useQuery, gql } from '@apollo/client';

const getStats = gql`
query GetReportsStats {
  getReportsStats {
    hour
    count
  }
}
`

export default () => {
  const { data, loading, error } = useQuery(getStats);
  console.log(data)
  if(loading) return <div>Loading</div>
  else if(error || !data.getReportsStats) return <div>Error</div>
  
  let freshData = [...data.getReportsStats].sort((a: any, b: any) => new Date(a.hour).getTime() - new Date(b.hour).getTime())
  console.log(freshData)
  freshData = freshData.map(row => ({...row, hour: new Date(+row.hour)}))
  return (<>
    <Paper>
      <BarChart
        width={700}
        height={400}
        data={freshData ? freshData : []}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <XAxis dataKey="hour" />
        <YAxis/>
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <Bar type="monotone" dataKey="count" fill='#52b69a' yAxisId={0} />
      </BarChart>
    </Paper>
  </>)
}
import { InMemoryCache, makeVar } from '@apollo/client';
import { Sentence, Message, Student, Tag, ReportSection, Template, Fragment } from './customTypes';
import { template } from './reportv2'

export const builderReportId = makeVar<number | undefined>(undefined);
export const builderSentences = makeVar<Sentence[]>([])
export const builderFetchingSentences = makeVar(false);
export const builderMode = makeVar('quick');
export const builderFetchingId = makeVar(0);
export const builderReportingSentenceIndexVar = makeVar(-1);
export const builderSavedStatus = makeVar<string | undefined>(undefined);
export const builderName = makeVar('');
export const builderGender = makeVar('she');
export const builderListA = makeVar<Tag[]>([]);
export const builderListB = makeVar<Tag[]>([]);
export const builderListC = makeVar<Tag[]>([]);
export const builderListD = makeVar<Tag[]>([]);
export const builderReportSections = makeVar<ReportSection[]>([]);
export const builderReportTemplate = makeVar<Template | undefined>(template);
export const builderTags = makeVar<Tag[]>([]);
export const builderAttentionName = makeVar(false);
export const builderAttentionRatings = makeVar(false);
export const builderDisableClearReportDialog = makeVar(false);
export const appAlertMessagesVar = makeVar<Message[]>([]);
export const className = makeVar('');
export const classStudents = makeVar<Student[]>([]);
export const adminIssueFragment = makeVar<Fragment | undefined>(undefined);
export const adminFragment = makeVar<Fragment | undefined>(undefined);
export const appOS = makeVar('Unknown');
export const appLogs = makeVar<any[]>([]);

export const cache = new InMemoryCache({
  typePolicies: {
    Product: {
      fields: { 
        builderSentences: { 
          read() {
            return builderSentences()
          }
        },
        builderFetchingSentences: {
          read() {
            return builderFetchingSentences()
          }
        },
        builderMode: {
          read() {
            return builderMode()
          }
        },
        builderFetchingId: {
          read() {
            return builderFetchingId()
          }
        },
        builderReportingSentenceIndexVar: {
          read() {
            return builderReportingSentenceIndexVar()
          }
        },
        builderReportIdVar: {
          read() {
            return builderReportId()
          }
        },
        builderNameVar: {
          read() {
            return builderName()
          }
        },
        builderGenderVar: {
          read() {
            return builderGender()
          }
        },
        builderAttentionName: {
          read() {
            return builderAttentionName()
          }
        },
        builderAttentionRatings: {
          read() {
            return builderAttentionRatings()
          }
        },
        builderDisableClearReportDialog: {
          read() {
            return builderDisableClearReportDialog()
          }
        },
        appAlertMessages: {
          read() {
            return appAlertMessagesVar()
          }
        },
        newClassStudents: {
          read() {
            return classStudents()
          }
        },
        newClassName: {
          read() {
            return className()
          }
        },
        builderTags: {
          read() {
            return builderTags();
          }
        },
        adminIssueFragment: {
          read() {
            return adminIssueFragment();
          }
        },
        adminFragment: {
          read() {
            return adminFragment();
          }
        },
        appOS: {
          read() {
            return appOS();
          }
        },
        logs: {
          read() {
            return appLogs();
          }
        }
      }
    }
  }
});
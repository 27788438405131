import './App.css';
import React, { Suspense, useEffect } from 'react';
import { Alert, Box } from '@mui/material';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Nav from './components/Nav';
import AppMessages from './components/AppMessages';
import Dashboard from './views/Dashboard';
import BuilderLoadingSkeleton from './components/BuilderLoadingSkeleton';
import GenericLoadingSkeleton from './components/GenericLoadingSkeleton';
import { appOS } from './cache';
import { useAuth0 } from '@auth0/auth0-react';

const Home = React.lazy(() => import('./views/Home'));
const Builder = React.lazy(() => import('./views/Builder'));
const Admin = React.lazy(() => import('./views/Admin'));
const Issues = React.lazy(() => import('./views/Issues'));
const Issue = React.lazy(() => import('./views/Issue'));
const Fragments = React.lazy(() => import('./views/Fragments'));
const NewFragments = React.lazy(() => import('./views/NewFragments'));
const Keys = React.lazy(() => import('./views/Keys'));
const Callback = React.lazy(() => import('./components/Callback'));
const EnterCode = React.lazy(() => import('./views/EnterCode'));
const Classes = React.lazy(() => import('./views/Classes'));
const Class = React.lazy(() => import('./views/Class'));
const Privacy = React.lazy(() => import('./views/Privacy'));
const FAQ = React.lazy(() => import('./views/FAQ'));

function App() {
  const location = useLocation();
  // const { user } = useAuth0();
  
  const loadingSkeleton = () => {
    console.log(location.pathname)
    if(location.pathname === '/maker')
      return <BuilderLoadingSkeleton/>
    else
      return <GenericLoadingSkeleton/>
  }
  
  useEffect(() => {
    // From https://www.delftstack.com/howto/javascript/javascript-detect-operating-system/
    var OS="Unknown";
      if (navigator.userAgent.indexOf("Win")!=-1) OS="Windows";
      if (navigator.userAgent.indexOf("Mac")!=-1) OS="MacOS";
      if (navigator.userAgent.indexOf("X11")!=-1) OS="UNIX";
      if (navigator.userAgent.indexOf("Linux")!=-1) OS="Linux";
    appOS(OS);
  }, [])

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh'
    }}>
      <Nav/>
      <AppMessages/>
      <Box sx={{
        margin: 'auto',
        width: {
          xl: '1000px',
          lg: '1000px',
          md: '83%',
          sm: '95%',
          xs: '95%'
        }
      }}>
        <Suspense fallback={loadingSkeleton()}>
          <Routes>
            <Route index element={<Home/>}/>
            <Route path='builder' element={<Builder/>}/>
            <Route path='faq' element={<FAQ/>}/>
            <Route path='classes' element={<Classes/>}/>
            <Route path='class/:id' element={<Class/>}/>
            <Route path='keycode' element={<EnterCode/>}/>
            <Route path='privacy' element={<Privacy/>}/>
            <Route path="admin" element={<Admin/>}>
              <Route index element={<Dashboard/>}/>
              <Route path="fragments" element={<Fragments/>}/>
              <Route path="newfragments" element={<NewFragments/>}/>
              <Route path='keys' element={<Keys/>}/>
              <Route path='issues/:status' element={<Issues/>}/>
              <Route path='issue/:id' element={<Issue/>}/>
              <Route path='issues' element={<Navigate to='review' replace />}/>
            </Route>
            <Route path='callback' element={<Callback/>} />
            <Route path='grader' element={<CustomRedirect to="https://legacy.eslreports.com/grader"/>}/>
            <Route path='bulk' element={<CustomRedirect to="https://legacy.eslreports.com/bulk"/>}/>
            <Route path='about' element={<CustomRedirect to="https://legacy.eslreports.com/about"/>}/>
            <Route path='contact' element={<CustomRedirect to="https://legacy.eslreports.com/contact"/>}/>
          </Routes>
        </Suspense>  
      </Box>
    </Box>
  );
}

const  CustomRedirect = (props: { to: string }) => {
  useEffect(() => {
    window.location.href = props.to;
  });
  return null;
}



export default App;

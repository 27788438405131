import { ReactNode } from 'react';
import {
  Typography,
  Box,
  Button
} from '@mui/material';

const PageHeading = (props: { children?: ReactNode }) => {
  const {children} = props;
  return (
    <Typography sx={{ color: 'secondary.light', marginTop: '8px', marginBottom: '16px', fontFamily: '"Bungee", cursive'}} variant='h4'>{children}</Typography>
  )
}

export default PageHeading;
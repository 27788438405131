
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloProviderWithAuth0 from './components/ApolloProviderWithAuth0';
import Auth0LoadingWrapper from './components/Auth0LoadingWrapper';

const domain = 'dev--wyl5vk3.jp.auth0.com';
const clientID = '1g5XVkDcSiLtcHNCwdna8J4DcgCDFVfd';
const redirectUri = process.env.NODE_ENV == 'production' ? 
                      process.env.REACT_APP_REDIRECT_URI :
                      process.env.REACT_APP_REDIRECT_URI_DEV;

const theme = createTheme({
  palette: {
    primary: {
      light: '#34a0a4',
      main: '#1a759f',
      dark: '#184e77',
      contrastText: '#fff',
    },
    secondary: {
      light: '#d9ed92',
      main: '#99d98c',
      dark: '#52b69a',
      contrastText: '#000',
    },
    background: {
      default: '#4f9b94'
    }
  },
  spacing: 8,
  typography: {
    fontFamily: '"Quicksand", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeightLight: '400',
    fontWeightRegular: '500',
    fontWeightMedium: '600'
  }
});

ReactDOM.render(
  <React.StrictMode>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <Auth0Provider
      domain={domain}
      clientId={clientID}
      redirectUri={redirectUri}
      audience={'http://localhost:4000'}
      scope='openid profile email metadata'
    >
      <ApolloProviderWithAuth0>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Auth0LoadingWrapper>
              <BrowserRouter>
                <Routes>
                  <Route path="*" element={<App/>}/>
                </Routes>
              </BrowserRouter>
            </Auth0LoadingWrapper>
        </ThemeProvider>
      </ApolloProviderWithAuth0>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Box
} from '@mui/material'
import LogoImage from '../eslreportslogo.png';
import { CSSProperties } from '@mui/styled-engine';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import ProfileButton from './ProfileButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const navButtonStyle: CSSProperties = {
    color: 'primary.dark',
    alignSelf: 'left',
    padding: '0 20px',
}

export default () => {
    const { user } = useAuth0();

    return (
        <AppBar position="static" sx={{
            backgroundColor: 'white',
            zIndex: '999'
        }}>
            <Toolbar>
                <Box sx={{ display: 'flex', flexGrow: 1}}>
                    <Typography fontSize={25} color={'black'}></Typography>
                    <img src={LogoImage} style={{ height: '50px'}} alt="" />
                    <Box sx={{ width: '50px'}}></Box>
                    <Button component={Link} to='/' sx={navButtonStyle}>Home</Button>
                    <Button component={Link} to='/builder'  sx={navButtonStyle}>⭐ Builder</Button>
                    <Button onClick={() => window.location.href = 'https://legacy.eslreports.com/grader'} sx={navButtonStyle}>Grader</Button>
                    <Button onClick={() => window.location.href = 'https://legacy.eslreports.com/bulk'} sx={navButtonStyle}>Bulk</Button>
                    <Button component={Link} to='/faq' sx={navButtonStyle}>FAQ</Button>
                    {user?.email === 'baird.miles@gmail.com' && (<Button component={Link} to='/classes'  sx={navButtonStyle}>Classes</Button>)}
                    {user?.email === 'baird.miles@gmail.com' && (<Button component={Link} to='/admin'  sx={navButtonStyle}>Admin</Button>)}
                </Box>
                {/* <LoginButton/>
                <Box sx={{ width: '16px'}}></Box>
                <SignupButton/>
                <ProfileButton/> */}
            </Toolbar>
        </AppBar>
    )
}
import {
  Box
} from '@mui/material';
import React from 'react';

const Page = (props: {children?: React.ReactNode}) => {
  const { children } = props;

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '16px',
      p: 2
    }}>
      {children}
    </Box>
  )
}

export default Page;